@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Colors on LightMode */
:root {
  --Brand-Light: #babce2;
  --Brand-Default: #1e208d;
  --Brand-Dark: #090b57;

  --DarkMode-Light: #233040;
  --DarkMode-Default: #1d2733;
  --DarkMode-Dark: #16202a;

  --Accent-Light: #1e208d;
  --Accent-Dark: #3639a3;
  --Accent-Negative: #c8cbda;
  --Accent-NegativeDark: #505a68;

  --Error-Light: #ff5e56;
  --Error-Light-20: rgba(255, 04, 86, 20%);
  --Error-Dark: #a40800;

  --Accept-Light: #03d7ae;
  --Accept-Light-20: rgba(3, 215, 174, 20%);
  --Accept-Dark: #016b57;

  --Attention-Default: #f5901e;
  --Attention-Light: #f8b060;

  --Actions-Default: #00c0c0;
  --Actions-Default-20: rgba(0, 192, 192, 20%);
  --Actions-Light: #00c0c0;
  --Actions-Dark: #505a68;

  --Title: #313235;
  --white: #ffffff;
  --white-gradianet: rgba(255, 255, 255, 0.7819502801120448);
  --white-gradianet-start: rgba(255, 255, 255, 0);
  --white-gradianet-end: rgb(255 255 255 / 100%);
  --white-background: #edeef3;

  --black: #000000;

  --borderSolid: #f0f1f6;
  --Highlightbackground: #f9f9f9;
  --SolidBackground: #f0f1f6;
  --graySolid: #edeef3;
  --Details: #b1b4c1;
  --BodyText: #5b5c63;

  --selfChat: #d4ffff;
  --lightBlue: #00c0c0;
  --lightGreen: #60bb46;
  --lightGreen-20: rgba(96, 187, 70, 0.2);
  --scroll-thumb: #b9b9b9;

  --toastify-font-family: Vazirmatn FD;
  --toastify-text-color-light: white;
  --toastify-icon-color-success: white;
  --toastify-icon-color-error: white;
  --toastify-toast-width: max-content;
  --toastify-z-index: 2147483646;
  --animate-duration: 400ms;
  --animate-delay: 0.1s;
}

/* react-toastify Custom Styles */
.Toastify__toast--error {
  background-color: var(--Error-Dark);
}

.Toastify__toast--success {
  background-color: var(--Accept-Light);
}

.Toastify__close-button > svg {
  color: white;
}

.Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

.Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

/* Custom Colors on DarkMode */
.dark {
  --scroll-thumb: #525760;
  --Brand-Light: #babce2;
  --Brand-Default: #dedbdb;
  --Brand-Dark: #090b57;

  --DarkMode-Light: #233040;
  --DarkMode-Default: #1d2733;
  --DarkMode-Dark: #16202a;

  --Accent-Light: #dedbdb;
  --Accent-Dark: #3639a3;
  --Accent-Negative: #c8cbda;
  --Accent-NegativeDark: #505a68;

  --Error-Light: #ff5e56;
  --Error-Light-20: rgba(255, 04, 86, 20%);
  --Error-Dark: #a40800;

  --Accept-Light: #03d7ae;
  --Accept-Light-20: rgba(3, 215, 174, 20%);
  --Accept-Dark: #016b57;

  --Attention-Default: #f5901e;
  --Attention-Light: #f8b060;

  --Actions-Default: #00c0c0;
  --Actions-Default-20: rgba(0, 192, 192, 20%);
  --Actions-Light: #00c0c0;
  --Actions-Dark: #505a68;

  --Title: #efede5;

  --white-gradianet: rgba(35, 48, 64, 0.7819502801120448);
  --white-gradianet-start: rgba(35, 48, 64, 0);
  --white-gradianet-end: rgb(35 48 64 / 100%);
  --white: #233040;
  --black: #ffffff;
  --Highlightbackground: #ffffff28;
  --SolidBackground: #212121;
  --graySolid: #1d2733;
  --borderSolid: #ffffff28;
  --Details: #7e7e7e;
  --BodyText: #d3d3d3;

  --selfChat: #d4ffff;
  --lightBlue: #00c0c0;
  --lightGreen: #60bb46;
  --lightGreen-20: rgba(96, 187, 70, 0.2);
}

@layer components {
  /* .my-react-select-container {
  } */
  .my-react-select-container .my-react-select__control {
    @apply border-2 border-neutral-700 bg-neutral-700 hover:border-neutral-500;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply shadow-none border-neutral-400 hover:border-neutral-400;
  }

  .my-react-select-container .my-react-select__menu {
    @apply border-2 border-neutral-600 bg-neutral-700;
  }

  .my-react-select-container .my-react-select__option {
    @apply bg-neutral-700 text-neutral-200 hover:bg-neutral-800;
  }

  .my-react-select-container .my-react-select__option--is-focused {
    @apply bg-neutral-800;
  }

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-neutral-400;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-neutral-200;
  }
}

/* styles for disabled react-select*/
.my-react-select--is-disabled {
  @apply !opacity-70;
}

/* Custom Styles for Calendar Component */
.dark .Calendar__monthText {
  color: var(--Title) !important;
}

.dark .Calendar__monthText:hover,
.dark .Calendar__yearText:hover {
  background-color: var(--DarkMode-Dark) !important;
}

.dark .Calendar__yearText {
  color: var(--Title) !important;
}

.dark .Calendar__monthArrow {
  filter: invert();
}

.dark .Calendar__monthSelector.-open,
.dark .Calendar__yearSelector.-open {
  background-color: var(--DarkMode-Dark);
}

.dark .Calendar__yearSelectorWrapper::before {
  background-image: linear-gradient(
    to top,
    var(--DarkMode-Dark),
    var(--DarkMode-Dark) 10%,
    rgba(245, 245, 245, 0)
  );
}

.dark .Calendar__yearSelectorWrapper::after {
  background-image: linear-gradient(
    to bottom,
    var(--DarkMode-Dark),
    var(--DarkMode-Dark) 10%,
    rgba(245, 245, 245, 0)
  );
}

.dark
  .Calendar__monthSelectorItem:not(.-active)
  .Calendar__monthSelectorItemText:not(:disabled):hover,
.dark
  .Calendar__yearSelectorItem:not(.-active)
  .Calendar__yearSelectorText:not(:disabled):hover {
  background: var(--Actions-Dark) !important;
}

.dark .Calendar__day {
  color: #ffffff !important;
}

.dark
  .Calendar__day:not(.-blank):not(.-disabled):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background: var(--Actions-Dark) !important;
  color: #ffffff !important;
}

.dark .Calendar__day.-disabled {
  color: #9d9d9d !important;
}

.dark .Calendar__day.-today {
  color: #ffffff !important;
}

/*Custom styles for react-loading-skeleton*/

.react-loading-skeleton {
  --base-color: var(--graySolid) !important;
  --highlight-color: var(--white) !important;
}

/* Custom Styles for Scrollbar */
@media (max-width: 640px) {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: initial;
  }
}

@media (min-width: 641px) {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: initial;
  }
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb);
  border-radius: 16px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

::-webkit-scrollbar-track {
  background-color: initial;
  border-radius: 7px;
  cursor: pointer;
}

/* Custom Styles for Icons in DarkMode */
.dark .icon {
  filter: brightness(500);
}

.dark .invertIcon {
  filter: invert();
}

.ReactCollapse--collapse {
  transition: height 250ms ease-in-out;
}

input:focus-within ~ label {
  @apply text-blue-500;
}

html {
  font-size: 90%;
  touch-action: manipulation;
}

html,
body {
  overscroll-behavior-y: none;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

@screen md {
  html {
    font-size: 95%;
  }
}

div,
a,
button,
select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
    background: red;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  background-clip: border-box;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--Title) !important;
  -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
  box-shadow: 0 0 0 30px var(--white) inset !important;
}

input:-webkit-autofill {
  animation-delay: 1s;
  animation-name: autofill;
  animation-fill-mode: both;
  -webkit-animation-delay: 1s;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

img {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.rtl::-moz-placeholder {
  text-align: right;
}

.rtl:-ms-input-placeholder {
  text-align: right;
}

.rtl::-webkit-input-placeholder {
  text-align: right;
}

.mystock-shadow {
  box-shadow: 0 0 30px -8px rgba(245, 144, 30, 0.75);
  -webkit-box-shadow: 0 0 30px -8px rgba(245, 144, 30, 0.75);
  -moz-box-shadow: 0 0 30px -8px rgba(245, 144, 30, 0.75);
}

.mobile-menu-shadow {
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
  -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
}

.dark .mobile-menu-shadow {
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.4)) !important;
  -webkit-filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.4)) !important;
}

.absolute-center-x {
  transform: translateX(-50%);
  left: 50%;
}

.absolute-center-y {
  transform: translateY(-50%);
  top: 50%;
}

.absolute-center {
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;
}

.web-drawer {
  background-image: url(./assets/img/pback.png);
  background-size: contain;
}

.gradient-menu-back {
  background: var(--white);
  background: linear-gradient(
    180deg,
    rgba(35, 48, 64, 0.9) 39%,
    rgba(35, 48, 64, 0.5) 100%
  );
}

.chat-bg {
  background-image: url(./assets/img/pback.png);
  background-size: 270px;
}

.chat-gradent-back {
  background: linear-gradient(
    180deg,
    var(--graySolid) 0%,
    var(--white-gradianet) 132%
  );
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.square {
  aspect-ratio: 1;
}

.hidden-bottom-more.is-closed::after {
  content: "";
  position: absolute;
  width: 100%;
  background: var(--white);
  background: linear-gradient(
    181deg,
    var(--white-gradianet-start) -30%,
    var(--white-gradianet-end) 75%
  );
  bottom: 0;
  height: 100px;
  pointer-events: none;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.custom-dots {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.custom-dots li {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.custom-dots li button:focus,
.custom-dots li button:hover {
  outline: 0;
}

.custom-dots li button:focus:before,
.custom-dots li button:hover:before {
  opacity: 1;
}

.custom-dots li button:before {
  font-size: 6px;
  transition: all 250ms ease-in-out;
  line-height: 20px;
  display: inline-block;
  top: 0;
  left: 0;
  height: 0.25rem;
  border-radius: 0.3rem;
  width: 0.5rem;
  content: "";
  text-align: center;
  margin: 0 2px;
  opacity: 1;
  background-color: #b1b4c1;
  color: #000;
}

.custom-dots li.slick-active button:before {
  background-color: var(--Brand-Default) !important;
  width: 1.15rem !important;
}

.custom-dots-light {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.custom-dots-light li {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-dots-light li button {
  font-size: 0;
  line-height: 0;
  display: block;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.custom-dots-light li button:focus,
.custom-dots-light li button:hover {
  outline: 0;
}

.custom-dots-light li button:focus:before,
.custom-dots-light li button:hover:before {
  opacity: 1;
}

.custom-dots-light li button:before {
  font-size: 6px;
  transition: all 250ms ease-in-out;
  line-height: 20px;
  display: inline-block;
  top: 0;
  left: 0;
  height: 0.25rem;
  border-radius: 0.3rem;
  width: 0.5rem;
  content: "";
  text-align: center;
  margin: 0 2px;
  opacity: 1;
  background-color: #b1b4c1;
  color: #000;
}

.custom-dots-light li.slick-active button:before {
  background-color: #fff !important;
  width: 1.15rem !important;
}

.rhap_play-pause-button {
  width: 1rem !important;
}

.rhap_controls-section {
  flex: none !important;
}

.rhap_progress-indicator {
  width: 15px;
  height: 15px;
  top: -5px;
}

.rhap_container {
  box-shadow: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.rhap_progress-filled {
  background-color: var(--lightBlue) !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noscrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noscrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.blurBack {
  backdrop-filter: blur(2px);
}

.simple-blur {
  filter: blur(2px);
}

.hard-blur {
  filter: blur(4px);
}

.g-recaptcha {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
}

/* fixing firefox select option font */
select,
option {
  font: -moz-pull-down-menu;
}

iframe[src="about:blank"] {
  display: none;
}

.swiper-pagination {
  left: 1rem !important;
  text-align: left !important;
}

.swiper-pagination-horizontal {
  position: relative !important;
  bottom: 1rem !important;
  left: 1rem;
}

.swiper-pagination-bullet-active {
  width: 2rem !important;
  border-radius: 1rem !important;
}

.swiper-pagination-bullet {
  height: 0.3rem !important;
  transition: all 300ms ease-in-out !important;
  background-color: var(--Brand-Default) !important;
}

.swiper {
  height: 100%;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.line-clamp-8 {
  -webkit-line-clamp: 8;
  line-clamp: 8;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.truncate-8 {
  width: 100%;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8; /* number of lines to show */
  line-clamp: 8;
  -webkit-box-orient: vertical;
}

.line-clamp-15 {
  -webkit-line-clamp: 15;
  line-clamp: 15;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.truncate-15 {
  width: 100%;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 15; /* number of lines to show */
  line-clamp: 15;
  -webkit-box-orient: vertical;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.truncate-3 {
  width: 100%;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.no-color * {
  color: unset !important;
  background: unset !important;
  pointer-events: none;
}

.image_btn {
  border-radius: 10px;
  max-width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto !important;
}

.marquee-container {
  overflow-y: hidden !important;
}
